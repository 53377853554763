/* eslint-disable import/no-cycle */
/* eslint-disable max-classes-per-file */
import React, { useState } from 'react'
// eslint-disable-next-line import/order
import { UserOutput } from './QueueOutput/OutputItems/UserOutput'
import 'react-toastify/dist/ReactToastify.css'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Checkbox,
  Container,
  FormControlLabel,
  FormLabel,
  FormGroup,
} from '@material-ui/core'
import { connect } from 'react-redux'
import LinearProgress from '@material-ui/core/LinearProgress'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import InfiniteScroll from 'react-infinite-scroll-component'
import { makeStyles } from '@material-ui/styles'
import { isSMSFlaresOptionAvailable } from 'modules/geofence/selectors'
import { isValidPhoneNumber } from 'react-phone-number-input'

import {
  isLoadingSelector,
  isSuccessSelector,
  reasonFailedSelector,
} from '../modules/requestsStatuses/selectors'
import { USERS_CREATE, USERS_EDIT, USERS_GET } from '../modules/users/actions'
import { rootUserSelector } from '../modules/user/selectors'

import {
  getHasMoreAgentsSelector,
  getUsersIdsByStationSelector,
} from '../modules/users/selectors'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'block',
    width: '100%',
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 4,
      paddingRight: 4,
    },
    paddingLeft: 10,
    paddingRight: 10,
    paddingBottom: 10,
    overflowY: 'auto',
    overflow: 'hidden',
    marginTop: 10,
  },
}))

const DisplayUserComponent = ({
  users,
  loadPaginatedUsers,
  isLoading,
  hasMoreAgents,
}) => {
  const classes = useStyles()
  const [page, setPage] = useState(1)
  const handleFetchUsers = () => {
    if (hasMoreAgents) {
      setPage(page + 1)
      loadPaginatedUsers({ page: page + 1 })
    }
  }

  return (
    <div className={classes.root} id="scroll-container">
      <div className={classes.listWrapper}>
        <InfiniteScroll
          next={handleFetchUsers}
          hasMore={hasMoreAgents}
          dataLength={users.length}
          style={{ overflow: 'visible' }}
          loader={isLoading && <h4>Loading...</h4>}
        >
          {users.map((userId) => (
            <UserOutput key={`item.username=${userId}`} id={userId} />
          ))}
        </InfiniteScroll>
        {users.length === 0 ? (
          <div
            style={{
              position: 'absolute',
              left: '50%',
              top: '50%',
              marginLeft: '-4.5rem',
              marginTop: '-.9rem',
              textAlign: 'center',
            }}
          >
            <h4 style={{ color: '#989898' }}>No Other Users</h4>
          </div>
        ) : null}
      </div>
    </div>
  )
}

function mapStateToProps(state) {
  return {
    currentUser: rootUserSelector(state),
    isLoading: isLoadingSelector(state, USERS_GET),
    users: getUsersIdsByStationSelector(state),
    hasMoreAgents: getHasMoreAgentsSelector(state),
  }
}

const mapDispatchToProps = {
  loadPaginatedUsers: USERS_GET.START.create,
}

export const DisplayUserList = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DisplayUserComponent)

// Add User Dialog
class AddUserComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      id: null,
      givenName: '',
      familyName: '',
      stationRoleAccess: '',
      email: '',
      phoneNumber: '',
      role: '',
      username: '',
      isEmailUsedForLogin: false,
      notificationSettings: {
        email: false,
        sms: false,
      },

      roles: ['Admin', 'Dispatcher'],

      errors: [],
    }
  }

  componentDidMount() {
    const { user, isEditMode } = this.props
    if (isEditMode) {
      this.initEditDialog(user)
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.isUserCreatedSuccess !== prevProps.isUserCreatedSuccess &&
      this.props.isUserCreatedSuccess
    ) {
      this.clearState()
    }
    if (
      this.props.reasonFailedSelector !== prevProps.reasonFailedSelector &&
      this.props.reasonFailedSelector &&
      this.props.reasonFailedSelector.length > 0
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ errors: [...this.props.reasonFailedSelector] })
    }
  }

  signUp = () => {
    this.setState({ errors: [] })
    const { isEditMode, createUser, onUserEdit } = this.props
    const {
      id,
      username,
      givenName,
      familyName,
      stationRoleAccess,
      email,
      phoneNumber,
      role,
      isEmailUsedForLogin,
      notificationSettings,
    } = this.state
    const newUsername = isEmailUsedForLogin ? null : username
    const userData = {
      givenName,
      familyName,
      email,
      phoneNumber,
      role,
      notificationSettings,
      username: newUsername,
    }
    if (isEditMode) {
      userData.id = id
      userData.stationRoleAccess = stationRoleAccess
      return onUserEdit(userData)
    }
    createUser(userData)
  }

  setValue = (key, value) => {
    this.setState((state) => ({ ...state, [key]: value }))
  } // Set state

  clearState = () => {
    this.setState({
      id: null,
      givenName: '',
      familyName: '',
      stationRoleAccess: '',
      email: '',
      phoneNumber: '',
      role: '',
      username: '',
      errors: [],
      notificationSettings: {
        email: false,
        sms: false,
      },
      isEmailUsedForLogin: false,
    })
  }

  dissmissError = (field) => {
    const { errors } = this.state
    if (errors && errors.length > 0) {
      const clearedErrorsArray = errors.filter((item) => item.field !== field)
      this.setState({ errors: clearedErrorsArray })
    }
  }

  handleFlareAlertsChange = (type) => {
    this.setState((state) => ({
      notificationSettings: {
        ...state.notificationSettings,
        [type]: !state.notificationSettings[type],
      },
    }))
  }

  phoneNumberFormat = (phoneNumber) =>
    phoneNumber.includes('+') ? phoneNumber : `+${phoneNumber}`

  initEditDialog = (user) => {
    this.setState(() => ({
      ...user,
      isEmailUsedForLogin: !user.username,
      phoneNumber: user.phoneNumber
        ? this.phoneNumberFormat(user.phoneNumber)
        : null,
    }))
  }

  render() {
    const {
      isLoading,
      isEditMode,
      isEditLoading,
      isEditStationUser,
      isEditCurrentUser,
      isSMSFlaresOption,
      currentUser,
    } = this.props
    const {
      errors,
      isEmailUsedForLogin,
      email,
      username,
      notificationSettings,
    } = this.state
    const usernameValue = isEmailUsedForLogin ? email : username

    return (
      <Dialog open={this.props.open} maxWidth="sm" fullWidth>
        {(isLoading || isEditLoading) && (
          <LinearProgress variant="indeterminate" style={styles.progress} />
        )}

        <DialogTitle id="form-dialog-title" style={{ paddingBottom: 0 }}>
          {isEditMode ? 'Edit User' : 'Add User'}
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            value={this.state.givenName}
            variant="standard"
            margin="dense"
            id="name"
            label="First Name"
            type="text"
            fullWidth
            required
            onChange={(evt) => this.setValue('givenName', evt.target.value)}
          />
          <TextField
            value={this.state.familyName}
            margin="dense"
            variant="standard"
            id="familyName"
            label="Last Name"
            type="text"
            fullWidth
            required
            onChange={(evt) => this.setValue('familyName', evt.target.value)}
          />
          <TextField
            value={this.state.email}
            margin="dense"
            id="email"
            variant="standard"
            label="Email Address"
            type="email"
            onFocus={() => this.dissmissError('email')}
            fullWidth
            error={
              (this.state.email !== '' &&
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                  this.state.email,
                ) === false) ||
              (this.state.errors &&
                !!this.state.errors.length &&
                !!this.state.errors.find(
                  (element) => element.field === 'email',
                ))
            }
            onChange={(evt) => this.setValue('email', evt.target.value)}
          />
          <PhoneInput
            buttonStyle={{
              color: 'grey',
              border: 'none',
              borderRadius: 'unset',
              borderBottom: '1px solid grey',
              backgroundColor: '#fff',
            }}
            style={{ margin: '1.5em 0 1em 0' }}
            inputStyle={{
              border: 'none',
              borderBottom: '1px solid grey',
              backgroundColor: '#fff',
              width: '100%',
              borderRadius: 'unset',
              color: '#000',
              boxShadow: 'unset',
            }}
            country="us"
            value={this.state.phoneNumber}
            onFocus={() => this.dissmissError('phoneNumber')}
            onChange={(value) => this.setValue('phoneNumber', `+${value}`)}
            onBlur={() => this.dissmissError('phoneNumber')}
            defaultErrorMessage='Invalid phone'
            isValid={(value) => {
              const phone = `+${value}`
              return isValidPhoneNumber(phone)
            }}
          />

          <Container maxWidth="xl" style={styles.checkboxContainer}>
            <FormControlLabel
              value="Username same as email"
              disableTypography
              control={
                <Checkbox
                  id="sameUsernameAsEmail"
                  checked={isEmailUsedForLogin}
                  color="primary"
                  onChange={(e) => {
                    const { checked } = e.target
                    this.setState({
                      isEmailUsedForLogin: checked,
                    })
                    if (!checked) {
                      this.setValue('username', '')
                    }
                  }}
                />
              }
              label="Username same as email"
              labelPlacement="end"
            />
          </Container>
          <TextField
            value={usernameValue}
            disabled={isEmailUsedForLogin}
            margin="dense"
            variant="standard"
            id="username"
            label="Username"
            type="username"
            fullWidth
            onFocus={() => this.dissmissError('username')}
            error={
              !!errors.length &&
              !!errors.find((element) => element.field === 'username')
            }
            onChange={(evt) => {
              this.setValue('username', evt.target.value)
            }}
          />

          <FormControl component="fieldset" style={styles.flareAlertsContainer}>
            <FormLabel component="legend">Notifications</FormLabel>
            <FormGroup>
              <FormControlLabel
                disableTypography
                control={
                  <Checkbox
                    checked={notificationSettings.email}
                    onChange={() => this.handleFlareAlertsChange('email')}
                    name="flareAlertsEmail"
                    color="primary"
                  />
                }
                label="email"
              />
              {isSMSFlaresOption && (
                <FormControlLabel
                  disableTypography
                  control={
                    <Checkbox
                      checked={notificationSettings.sms}
                      disabled={!isEditCurrentUser}
                      onChange={() => this.handleFlareAlertsChange('sms')}
                      name="flareAlertsSms"
                      color="primary"
                    />
                  }
                  label="sms"
                />
              )}
            </FormGroup>
          </FormControl>
          <FormControl fullWidth margin="dense">
            <InputLabel>Role</InputLabel>
            <Select
              value={this.state.role}
              variant="standard"
              onChange={(evt) => this.setValue('role', evt.target.value)}
              id="role"
              name="role"
              disabled={isEditMode && currentUser.role !== 'Admin'}
              required
            >
              {this.state.roles.map((value) => (
                <MenuItem key={value} value={value}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          {isEditStationUser && (
            <div style={{ flex: '1 0 0' }}>
              <Button
                onClick={() => {
                  this.props.setConfirmResetPassword(true)
                }}
                color="error"
              >
                Reset Password
              </Button>
            </div>
          )}
          <Button
            onClick={() => {
              this.clearState()
              this.props.close()
            }}
            color="primary"
          >
            Close
          </Button>
          {this.state.givenName === '' ||
          this.state.familyName === '' ||
          (this.state.email === '' ||
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
              this.state.email,
            )) === false ||
          this.state.phoneNumber === '' ||
          this.state.phoneNumber.length < 12 ||
          !isValidPhoneNumber(this.state.phoneNumber) ||
          this.state.role === '' ? (
            // Check if all inputs are filled & valid before allowing user to confirm creation
            <Button color="primary" disabled>
              {isEditMode ? 'Save' : 'Create'}
            </Button>
          ) : (
            <Button onClick={this.signUp} color="primary">
              {isEditMode ? 'Save' : 'Create'}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    )
  }
}

function mapStateToPropsAddUser(state) {
  return {
    currentUser: rootUserSelector(state),
    isLoading: isLoadingSelector(state, USERS_CREATE),
    isUserCreatedSuccess: isSuccessSelector(state, USERS_CREATE),
    reasonFailedSelector: reasonFailedSelector(state, USERS_CREATE),
    isSMSFlaresOption: isSMSFlaresOptionAvailable(state),
  }
}

const mapDispatchToPropsAddUser = {
  createUser: USERS_CREATE.START.create,
  editUser: USERS_EDIT.START.create,
}

export const AddUser = connect(
  mapStateToPropsAddUser,
  mapDispatchToPropsAddUser,
)(AddUserComponent)

const styles = {
  progress: {
    width: '100%',
    height: '4px',
  },
  checkboxContainer: {
    display: 'flex',
    flexDirection: 'row',
    padding: 0,
    marginTop: '5px',
    alignItems: 'center',
  },
  checkboxDescription: {
    fontSize: '16px',
    lineHeight: '39.5px',
  },
  flareAlertsContainer: {
    marginTop: 15,
  },
}
